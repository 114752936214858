<template>
  <div class="template-name-position">
    <h1>
      {{ candidate.name }}
    </h1>
    <h2>For {{ setPosition(candidate.positionId) }}</h2>
  </div>
</template>

<script>
import { setPosition } from "@/services/helpers";

export default {
  data() {
    return {
      title: "candidate name",
      subTitle: "for board of directors"
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  methods: {
    setPosition: setPosition
  }
};
</script>

<style lang="scss" scoped></style>
